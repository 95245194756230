import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { TenantTemplateOptionModel } from 'baseflow-auth';
import { Observable, OperatorFunction, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpHelpers, fade } from 'shared';

import { TenantService } from '../../../services';
import { WizardStepBaseComponent } from '../base/wizard-step-base.component';


@Component({
    selector: 'app-wizard-step-tenant',
    templateUrl: './wizard-step-tenant.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', './wizard-step-tenant.component.scss'],
    animations: [fade()],
})
export class WizardStepTenantComponent extends WizardStepBaseComponent {
    public templates: Array<TenantTemplateOptionModel> = [];
    public selectedTemplate?: TenantTemplateOptionModel;
    protected tenantCreationError?: string;

    public constructor(private readonly tenantService: TenantService) {
        super();
    }

    public show(): void {
        this.getTemplates();
        super.show();
    }

    @HostListener('keyup.enter')
    public onSubmit(): void {
        this.stepSubmit.emit();
    }

    public setSelectedTemplate(): void {
        this.selectedTemplate = this.templates.find((t) => t.id === this.form.tenantTemplateId) ?? undefined;
    }

    public trackById(index: number, item: { id: string }): string {
        return item.id;
    }

    protected submitWizardStep(): Observable<boolean> {
        return this.tenantService.createTenant(this.form.tenantName).pipe(
            catchError((error) => this.handleTenantCreationError(error)),
            switchMap((tenant) => {
                if (tenant === false) return of(false);
                this.tenantCreationError = undefined;
                this.tenantService.setActiveTenant(tenant);
                return this.tenantService.updateTenant(this.form.tenantName, this.selectedTemplate.id);
            }),
            catchError((error) => this.handleTenantCreationError(error)),
            map((tenant) => tenant !== false)
        );
    }

    private handleTenantCreationError(err: HttpErrorResponse): Observable<false> {
        console.error(err);
        this.tenantCreationError = 'errors.tenantCreationError';
        return of(false);
    }

    private getTemplates(): void {
        this.tenantService
            .getTenantTemplates('en')
            .pipe(
                HttpHelpers.logError as OperatorFunction<
                    Array<TenantTemplateOptionModel>,
                    Array<TenantTemplateOptionModel>
                >,
                tap((response: Array<TenantTemplateOptionModel>) => (this.templates = response))
            )
            .subscribe();
    }
}
