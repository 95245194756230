import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import { ApiVersion, UserRegistrationModel, UserRegistrationResponseModel } from '../models';
import { AuthConfigRepositoryService } from './auth-config-repository.service';

@Injectable({
    providedIn: 'root',
})
export class UserRepositoryService {
    public constructor(private readonly http: HttpClient, private readonly authConfig: AuthConfigRepositoryService) {}

    public registerUser(user: UserRegistrationModel): Observable<UserRegistrationResponseModel> {
        return this.getApiUrl('/registration', 'v1').pipe(
            mergeMap((url) => this.http.post<UserRegistrationResponseModel>(url, user))
        );
    }

    private getApiUrl(resource: string, apiVersion: ApiVersion): Observable<string> {
        return this.authConfig.config.pipe(
            take(1),
            map(
                (config) =>
                    `${config.usersApiBaseUrl}/${apiVersion}${resource.startsWith('/') ? resource : '/' + resource}`
            )
        );
    }
}
