import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { BehaviorSubject, Observable, OperatorFunction, ReplaySubject, firstValueFrom } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { REPOSITORY_BASE_URL_TOKEN, RepositoryBaseUrlConfig } from './repositories/base-repository.service';

export abstract class AppSettingsService<TAppSettings> {
    private readonly _appSettings: ReplaySubject<TAppSettings> = new ReplaySubject<TAppSettings>(1);
    public readonly appSettings: Observable<TAppSettings> = this._appSettings.asObservable();

    protected constructor(
        private http: HttpClient,
        @Inject(REPOSITORY_BASE_URL_TOKEN) private baseUrlConfig: BehaviorSubject<RepositoryBaseUrlConfig>
    ) {}

    public initializeApp(appSettingsPath = 'assets/appsettings.json'): Promise<TAppSettings> {
        return firstValueFrom(
            this.http.get<TAppSettings>(appSettingsPath).pipe(
                tap((response) => this._appSettings.next(response)),
                tap((r) => {
                    const response = r as {
                        url?: {
                            api?: {
                                users?: string;
                                tenants?: string;
                                tasks?: string;
                                reporting?: string;
                                assets?: string;
                            };
                        };
                    };
                    const api = response?.url?.api;
                    this.baseUrlConfig.next({
                        userApi: api?.users ?? undefined,
                        tenantApi: api?.tenants ?? undefined,
                        taskApi: api?.tasks ?? undefined,
                        reportingApi: api?.reporting ?? undefined,
                        observationApi: api?.assets ?? undefined,
                        assetApi: api?.assets ?? undefined,
                    });
                }),
                catchError((e) => {
                    console.error(e);
                    throw e;
                })
            )
        );
    }

    public get<T>(operator: OperatorFunction<TAppSettings, T>): Observable<T> {
        return this.appSettings.pipe(take(1), operator);
    }
}
