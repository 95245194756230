import { TranslateService } from '@ngx-translate/core';

import { IEnvironment } from '../models';
import { AppSettingsService } from '../services';

export function BaseAppInitializerFactory<TAppSettings>(
    translate: TranslateService,
    appSettingsService: AppSettingsService<TAppSettings>,
    environment: IEnvironment
) {
    return async (): Promise<void> => {
        // Load app settings
        await appSettingsService.initializeApp();
        // Set translation defaults
        translate.setDefaultLang(environment.fallbackLocale);

        environment.supportedLocales.forEach((locale) => translate.reloadLang(locale));
    };
}
