import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'baseflow-auth';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { AppSettingsService, TenantService } from '../../../services';
import { WizardStepBaseComponent } from '../base/wizard-step-base.component';


@Component({
    selector: 'app-wizard-step-create-account',
    templateUrl: './wizard-step-create-account.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', 'wizard-step-create-account.component.scss'],
})
export class WizardStepCreateAccountComponent extends WizardStepBaseComponent {
    public termsUrl: string;

    @Output() public goToLogin: EventEmitter<void> = new EventEmitter();

    public constructor(
        private readonly appSettings: AppSettingsService,
        private readonly translate: TranslateService,
        private readonly auth: AuthService,
        private readonly tenantService: TenantService
    ) {
        super();

        appSettings.get(tap((settings) => (this.termsUrl = settings.url.terms))).subscribe();
    }

    @HostListener('keyup.enter')
    public onSubmit(): void {
        this.stepSubmit.emit();
    }

    public checkPasswordsMatch(): void {
        const controls = (this.stepForm as any).form.controls;
        const password = controls.password as UntypedFormControl;
        const confirm = controls.passwordConfirm as UntypedFormControl;

        confirm.setErrors(confirm.value === password.value ? null : { mismatch: true });
    }

    protected submitWizardStep(): Observable<boolean> {
        const data = this.form;

        return this.auth
            .registerUser({
                username: data.emailAddress,
                password: data.password,
                fullname: data.username,
                language: this.translate.currentLang,
                emailAddress: data.emailAddress,
            })
            .pipe(
                catchError((response) => {
                    if (response?.error?.errorCode === 'UsernameTaken')
                        (this.formControl as any).controls.emailAddress.setErrors({ usernameTaken: true });

                    return of(false) as Observable<false>;
                }),
                switchMap((response) => (response === false ? of(false) : this.login()))
            );
    }

    private login(): Observable<boolean> {
        return (this.auth.isLoggedInSync() ? from(this.auth.logout()) : of(null)).pipe(
            take(1),
            switchMap(() => from(this.auth.login(this.form.emailAddress, this.form.password))),
            catchError((error) => this.handleLoginError(error)),
            map((result) => {
                return result !== false;
            })
        );
    }

    private handleLoginError(err: { code?: string; details: unknown }): Observable<false> {
        console.error(err);
        return of(false);
    }
}
