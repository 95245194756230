import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export class HttpHelpers {
    public static responseToBoolean = switchMap((response) => of(response !== false));

    public static logError = catchError((error) => {
        console.error(error);
        return of(false);
    });
}
