import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TenantService } from '../services/tenant.service';

@Injectable({ providedIn: 'root' })
export class TenantInterceptor implements HttpInterceptor {
    public constructor(private tenantService: TenantService) {}

    intercept(req: HttpRequest<HttpEvent<unknown>>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const tenant = this.tenantService.getActiveTenant();
        if (tenant) req = req.clone({ headers: req.headers.set('OneGo-Tenant', tenant.id) });
        return next.handle(req);
    }
}
