import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BehaviorSubject, Observable, firstValueFrom, of } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';

import { AuthUser, UserRegistrationModel, UserRegistrationResponseModel } from '../../models';
import {
    StoredTokenSet,
    StoredTokenSetSchema,
    TokenSet,
    getStoredValueForTokenSet,
    getTokenSetForResponse,
    getTokenSetForStoredValue,
} from '../../models/token-set.model';
import { AuthRepositoryService, UserRepositoryService } from '../../repositories';

export const TENANT_KEY = 'ACTIVE_TENANT_ID';
const TOKEN_SET_KEY = 'AUTH_TOKEN_SET';
const ACCESS_TOKEN_REFRESH_PERIOD = 1000 * 60 * 5;
const MIN_REFRESH_PERIOD = 1000 * 60 * 1;

@Injectable({ providedIn: 'root' })
export class AuthService {
    private initialized = false;
    public readonly tokenSet: BehaviorSubject<TokenSet | null | undefined> = new BehaviorSubject<
        TokenSet | null | undefined
    >(undefined);
    public readonly loggedIn: Observable<boolean> = this.tokenSet.pipe(
        filter((v) => v !== undefined),
        map((set) => !!set)
    );

    public readonly user: Observable<AuthUser> = this.tokenSet.pipe(
        filter((v) => v !== undefined),
        map((set) => set?.user)
    );

    public constructor(
        private readonly storage: StorageMap,
        private readonly authRepository: AuthRepositoryService,
        private readonly userRepository: UserRepositoryService
    ) {}

    public getTokenSet(): TokenSet {
        return this.tokenSet.value;
    }

    public async login(username: string, password: string): Promise<void> {
        try {
            // Attempt a log in
            const resp = await firstValueFrom(this.authRepository.login(username, password));
            // Extract a token set from the response
            const tokenSet = getTokenSetForResponse(resp);
            // Persist the token set
            await this.saveTokenSet(tokenSet);
            // Set the token set as the current set
            this.tokenSet.next(tokenSet);
        } catch (e) {
            await this.logout();
            if (e instanceof HttpErrorResponse) {
                switch (e.status) {
                    case 401:
                    case 403:
                        if (e.error?.errorCode === 'NOT_AUTHORIZED') throw { code: 'auth/invalid-credentials' };
                        break;
                    case 500:
                        throw { code: 'auth/internal-server-error' };
                    case 0:
                        throw { code: 'auth/service-unavailable' };
                    default:
                        break;
                }
            }
            throw e;
        }
    }

    public async logout(): Promise<void> {
        this.tokenSet.next(null);
        await firstValueFrom(this.storage.delete(TOKEN_SET_KEY));
    }

    public isLoggedInSync(): boolean {
        return !!this.tokenSet.value;
    }

    public registerUser(user: UserRegistrationModel): Observable<UserRegistrationResponseModel> {
        return this.userRepository.registerUser(user);
    }

    public async init(): Promise<void> {
        this.handleAutoTokenRefresh();
        try {
            await this.loadTokenSet();
        } catch (e) {
            await this.logout();
        }
        this.initialized = true;
    }

    private handleAutoTokenRefresh() {
        this.tokenSet
            .pipe(
                switchMap((tokenSet) => {
                    if (!tokenSet) return of(null);
                    const refreshDelay = Math.max(
                        MIN_REFRESH_PERIOD,
                        tokenSet.accessTokenExpiry.getTime() - Date.now() - ACCESS_TOKEN_REFRESH_PERIOD
                    );
                    return of(tokenSet).pipe(delay(refreshDelay));
                }),
                filter((tokenSet) => !!tokenSet)
            )
            .subscribe((tokenSet: TokenSet) => this.refreshTokens(tokenSet));
    }

    private async refreshTokens(tokenSet: TokenSet): Promise<TokenSet> {
        try {
            const resp = await firstValueFrom(this.authRepository.refresh(tokenSet.accessToken, tokenSet.refreshToken));
            const newTokens = getTokenSetForResponse(resp);
            this.tokenSet.next(newTokens);
            await this.saveTokenSet(newTokens);
            return newTokens;
        } catch (e) {
            await this.logout();
            return null;
        }
    }

    private async loadTokenSet(): Promise<TokenSet> {
        if (!(await firstValueFrom(this.storage.has(TOKEN_SET_KEY)))) {
            this.tokenSet.next(null);
            return null;
        }
        // Get token set from storage
        const tokenSet: TokenSet = getTokenSetForStoredValue(
            await firstValueFrom(this.storage.get<StoredTokenSet>(TOKEN_SET_KEY, StoredTokenSetSchema))
        );
        // Check if the access token is about to expire
        if (tokenSet.accessTokenExpiry.getTime() - Date.now() < ACCESS_TOKEN_REFRESH_PERIOD) {
            // Attempt to refresh the token set
            return this.refreshTokens(tokenSet);
        }
        // If it's not about to expire, just load it normally
        else {
            // Activate the token set and return it
            this.tokenSet.next(tokenSet);
            return tokenSet;
        }
    }

    private async saveTokenSet(tokenSet: TokenSet): Promise<void> {
        await firstValueFrom(
            this.storage.set(TOKEN_SET_KEY, getStoredValueForTokenSet(tokenSet), StoredTokenSetSchema)
        );
    }
}
