<form #stepForm="ngForm" class="form-page" *ngIf="visible">
    <label>
        {{ 'form.tenantName' | translate }}
        <input
            type="text"
            name="tenantName"
            required
            [placeholder]="'form.tenantName' | translate"
            [(ngModel)]="form.tenantName"
            validationErrors
            [minlength]="2"
            [maxlength]="255"
        />
    </label>
    <label>
        {{ 'form.sector' | translate }}
        <select name="sector" required [(ngModel)]="form.tenantTemplateId" (change)="setSelectedTemplate()">
            <option disabled [value]="undefined">{{ 'form.sector' | translate }}</option>
            <option *ngFor="let template of templates; trackBy: trackById" [value]="template.id">
                {{ template.label }}
            </option>
        </select>
    </label>
    <div class="tenant-error" @fade *ngIf="tenantCreationError">{{ tenantCreationError | translate }}</div>
    <div *ngIf="selectedTemplate">
        {{ selectedTemplate.description }}
    </div>
</form>
