<div class="page-container">
    <div class="wizard" *ngIf="wizardSteps">
        <div class="logo">
            <img src="../../../assets/img/logo.png" [alt]="'general.brandLogo' | translate" />
        </div>
        <div class="backlink">
            <span class="material-icons">arrow_back</span>
            <a [href]="backLinkUrl">onego.io</a>
        </div>
        <div class="dialog">
            <div class="page-image">
                <img [src]="'assets/img/' + step.image" [alt]="'steps.titles.' + step.title | translate" />
                <span class="page-image-tenant" *ngIf="currentStep === 2">{{ formData.tenantName }}</span>
            </div>
            <div class="steps">
                <h1 class="steps-title">
                    {{ 'steps.titles.' + step.title | translate }}
                </h1>
                <span class="steps-number">
                    {{
                        'steps.stepXOfTotal'
                            | translate
                                : {
                                      stepNumber: this.getVisualStepIndex(),
                                      totalSteps
                                  }
                    }}
                </span>
                <span class="steps-bar">
                    <span class="steps-bar-fill" [style.width]="getBarFillPercentage()"></span>
                </span>
            </div>
            <div class="form">
                <app-wizard-step-login
                    [form]="formData"
                    (stepSubmit)="nextStep()"
                    (goToSignup)="goToPageById('account')"
                ></app-wizard-step-login>
                <app-wizard-step-create-account
                    [form]="formData"
                    (stepSubmit)="nextStep()"
                    (goToLogin)="goToPageById('login')"
                ></app-wizard-step-create-account>
                <app-wizard-step-tenant [form]="formData" (stepSubmit)="nextStep()"></app-wizard-step-tenant>
                <app-wizard-step-location [form]="formData" (stepSubmit)="nextStep()"></app-wizard-step-location>
                <app-wizard-step-done [form]="formData" (stepSubmit)="nextStep()"></app-wizard-step-done>
            </div>
            <div class="controls">
                <button
                    *ngIf="!step.component()?.nextPageLink"
                    type="button"
                    class="btn color-secondary"
                    (click)="nextStep()"
                    [disabled]="nextDisabled"
                >
                    <span *ngIf="!nextDisabled" @fade>{{ 'buttons.' + step.nextButton | translate }}</span>
                    <ogs-spinner *ngIf="nextDisabled" @fade></ogs-spinner>
                </button>
                <a
                    *ngIf="step.component()?.nextPageLink"
                    [href]="step.component()?.nextPageLink"
                    target="_blank"
                    class="btn color-secondary"
                >
                    {{ 'buttons.' + step.nextButton | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
