import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthConfigRepositoryService, AuthService, BaseflowAuthModule } from 'baseflow-auth';
import { ToastrModule } from 'ngx-toastr';
import {
    AuthInitializerFactory,
    BaseAppInitializerFactory,
    ENVIRONMENT,
    MAP_AUTH_CONFIG,
    SharedModule,
    SpinnerComponent,
} from 'shared';

import buildVersion from '../assets/build-version.json';
import { environment } from '../environments/environment';
import { AppComponent } from './components/app/app.component';
import { WizardMapComponent } from './components/wizard-map/wizard-map.component';
import {
    WizardStepCreateAccountComponent,
    WizardStepDoneComponent,
    WizardStepLocationComponent,
    WizardStepLoginComponent,
    WizardStepTenantComponent,
} from './components/wizard-step';
import { WizardComponent } from './components/wizard/wizard.component';
import { ValidationErrorsDirective } from './directives/validation-errors.directive';
import { MapAuthConfigFactory, TranslateLoaderFactory } from './factories';
import { TenantInterceptor } from './interceptors/tenant-interceptor.service';
import { AppSettings } from './models/app-settings.model';
import { AppSettingsService, TenantService } from './services';

@NgModule({
    declarations: [
        AppComponent,
        ValidationErrorsDirective,
        WizardComponent,
        WizardMapComponent,
        WizardStepLoginComponent,
        WizardStepCreateAccountComponent,
        WizardStepDoneComponent,
        WizardStepLocationComponent,
        WizardStepTenantComponent,
    ],
    imports: [
        // NG modules
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule,
        HttpClientModule,
        RouterModule.forRoot([{ path: '**', redirectTo: '' }]),
        // Package modules
        SharedModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [],
            },
        }),
        // Application modules
        BaseflowAuthModule,
        // Standalone components
        SpinnerComponent,
    ],
    providers: [
        { provide: ENVIRONMENT, useValue: environment },
        {
            provide: APP_INITIALIZER,
            useFactory: BaseAppInitializerFactory<AppSettings>,
            deps: [TranslateService, AppSettingsService, ENVIRONMENT],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: AuthInitializerFactory,
            deps: [AppSettingsService, AuthConfigRepositoryService, MAP_AUTH_CONFIG, AuthService],
            multi: true,
        },
        {
            provide: MAP_AUTH_CONFIG,
            useValue: MapAuthConfigFactory,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TenantInterceptor,
            multi: true,
            deps: [TenantService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    public constructor(translate: TranslateService) {
        console.log(`Initializing Onboarding Web client v${buildVersion.buildVersion}...`);
        translate.use(environment.defaultLocale);
    }
}
