import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { fade } from 'shared';

import { IWizardForm, IWizardStep } from '../../interfaces';
import { AppSettingsService } from '../../services';
import {
    WizardStepBaseComponent,
    WizardStepCreateAccountComponent,
    WizardStepDoneComponent,
    WizardStepLocationComponent,
    WizardStepLoginComponent,
    WizardStepTenantComponent,
} from '../wizard-step';

@Component({
    selector: 'app-onboarding-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss'],
    animations: [fade()],
})
export class WizardComponent implements AfterViewInit {
    public backLinkUrl: string;
    public nextDisabled = false;

    @ViewChild(WizardStepLoginComponent) protected stepLogin: WizardStepLoginComponent;
    @ViewChild(WizardStepCreateAccountComponent) protected stepCreateAccount: WizardStepCreateAccountComponent;
    @ViewChild(WizardStepTenantComponent) protected stepTenant: WizardStepTenantComponent;
    @ViewChild(WizardStepLocationComponent) protected stepLocation: WizardStepLocationComponent;
    @ViewChild(WizardStepDoneComponent) protected stepDone: WizardStepDoneComponent;

    public wizardSteps: Array<IWizardStep> = [
        {
            title: 'login',
            image: 'page_1.svg',
            nextButton: 'login',
            component: (): WizardStepBaseComponent => this.stepLogin,
            optional: false,
            skipNextSteps: 1,
        },
        {
            title: 'account',
            image: 'page_1.svg',
            nextButton: 'signUp',
            component: (): WizardStepBaseComponent => this.stepCreateAccount,
            optional: true,
        },
        {
            title: 'tenant',
            image: 'page_2.svg',
            nextButton: 'next',
            component: (): WizardStepBaseComponent => this.stepTenant,
            optional: false,
        },
        {
            title: 'location',
            image: 'page_3.svg',
            nextButton: 'next',
            component: (): WizardStepBaseComponent => this.stepLocation,
            optional: false,
        },
        {
            title: 'done',
            image: 'page_4.png',
            nextButton: 'toPortal',
            component: (): WizardStepBaseComponent => this.stepDone,
            optional: false,
        },
    ];

    public formData: IWizardForm = {} as IWizardForm;
    public currentStep = 0;

    public get totalSteps(): number {
        return this.wizardSteps.filter((s) => !s.optional).length;
    }

    public get step(): IWizardStep {
        return this.wizardSteps[this.currentStep];
    }

    public constructor(appSettings: AppSettingsService) {
        appSettings.get(tap((settings) => (this.backLinkUrl = settings.url.backLink))).subscribe();
    }

    public ngAfterViewInit(): void {
        this.step.component().show();
    }

    public getVisualStepIndex(): number {
        const requiredSteps = this.wizardSteps.filter((s) => !s.optional);
        const closestRequiredStep = !this.step.optional
            ? this.step
            : this.wizardSteps
                  .slice(0, this.currentStep + 1)
                  .reverse()
                  .find((e) => !e.optional);
        return requiredSteps.indexOf(closestRequiredStep) + 1;
    }

    public getBarFillPercentage(): string {
        return `${(this.getVisualStepIndex() * 100) / this.totalSteps}%`;
    }

    public nextStep(skip = 0): void {
        this.nextDisabled = true;

        (this.step.component().validateAndSubmitForm() ?? of(true))
            .pipe(
                take(1),
                tap((isValid) => {
                    if (isValid && this.currentStep < this.wizardSteps.length - 1) {
                        this.step.component().hide();
                        this.currentStep += 1 + (this.step.skipNextSteps || 0);
                        this.step.component().show();
                    }
                    this.nextDisabled = false;
                })
            )
            .subscribe();
    }

    protected goToPageById(id: string): void {
        this.step.component().hide();
        this.currentStep = this.wizardSteps.findIndex((s) => s.title === id);
        this.step.component().show();
    }
}
