import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { BFAuthConfig } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthConfigRepositoryService {
    private initialized = false;
    private readonly _config: ReplaySubject<BFAuthConfig> = new ReplaySubject<BFAuthConfig>(1);
    public readonly config: Observable<BFAuthConfig> = this._config.asObservable();

    async initialize(config: BFAuthConfig) {
        if (this.initialized) {
            console.warn('Baseflow AuthConfigService cannot be initialized more than once.');
            return;
        }
        this.checkForConfigurationIssues(config);
        this._config.next(config);
        this.initialized = true;
    }

    private checkForConfigurationIssues(config: BFAuthConfig) {
        if (config.baseUrl.match(/v[0-9]\/?$/)) {
            console.warn('Baseflow AuthConfigService: The baseUrl should not end with a version number.');
        }
    }
}
