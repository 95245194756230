import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { TranslateService } from '@ngx-translate/core';
import mapboxgl, { Map } from 'mapbox-gl';
import { IMapConfig } from 'shared';

import { environment } from '../../../environments/environment';

const ZOOM_MAX = 22;
const ZOOM_MIN = 5;

@Component({
    selector: 'app-wizard-map',
    templateUrl: './wizard-map.component.html',
    styleUrls: ['./wizard-map.component.scss'],
})
export class WizardMapComponent implements OnDestroy, AfterViewInit {
    @Input()
    protected disabled = false;

    @ViewChild('mapElement')
    public mapElement: ElementRef;

    private map: Map;
    private geocoder: MapboxGeocoder;
    private mapConfig: Omit<IMapConfig, 'id' | 'ordinals'> = {
        mapBox: {
            token: environment.mapBox.token,
            style: environment.mapBox.style,
        },
        centerCoordinates: {
            latitude: 52.1326,
            longitude: 5.2913,
        },
        zoom: 5,
        rotation: 0,
        zoomBoundaries: {
            min: ZOOM_MIN,
            max: ZOOM_MAX,
        },
    };

    public constructor(private readonly translate: TranslateService) {}

    public geocodeLocation(searchString: string): void {
        this.geocoder.query(searchString);
    }

    public async ngAfterViewInit(): Promise<void> {
        // Dispose of previous map
        if (this.map) {
            this.map.remove();
            // this.mapService.disposeMap(this.map);
            this.map = null;
        }
        // Build a new map if a config was provided
        if (this.mapConfig) this.buildMap();
    }

    public getSettings(): Omit<IMapConfig, 'id' | 'ordinals'> {
        const center = this.map.getCenter();
        return {
            mapBox: {
                token: environment.mapBox.token,
                style: environment.mapBox.style,
            },
            centerCoordinates: {
                latitude: center.lat,
                longitude: center.lng,
            },
            zoom: Math.round(this.map.getZoom()),
            zoomBoundaries: {
                min: 10,
                max: ZOOM_MAX,
            },
            rotation: this.map.getBearing(),
        };
    }

    public ngOnDestroy(): void {
        if (this.map) this.map.remove();
    }

    private buildMap(): void {
        const mapboxToken = this.mapConfig.mapBox.token;
        const mapboxStyle = this.mapConfig.mapBox.style;

        this.geocoder = new MapboxGeocoder({
            accessToken: mapboxStyle && mapboxToken,
            language: this.translate.currentLang,
            marker: true,
            collapsed: true,
            mapboxgl,
        });

        // Create map element
        this.map = new Map({
            accessToken: mapboxStyle && mapboxToken,
            style: mapboxToken && mapboxStyle,
            container: this.mapElement.nativeElement,
            center: [this.mapConfig.centerCoordinates.longitude, this.mapConfig.centerCoordinates.latitude],
            minZoom: this.mapConfig.zoomBoundaries.min,
            maxZoom: this.mapConfig.zoomBoundaries.max,
            zoom: this.mapConfig.zoom,
            bearing: this.mapConfig.rotation,
        }).addControl(this.geocoder);
    }
}
