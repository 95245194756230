<form #stepForm="ngForm" class="form-page" *ngIf="visible">
    <label>
        {{ 'form.username' | translate }}
        <input
            type="text"
            name="username"
            required
            [placeholder]="'form.username' | translate"
            [(ngModel)]="form.username"
            validationErrors
        />
    </label>
    <label>
        {{ 'form.emailAddress' | translate }}
        <input
            type="text"
            name="emailAddress"
            required
            email
            [placeholder]="'form.emailAddress' | translate"
            [(ngModel)]="form.emailAddress"
            validationErrors
        />
    </label>
    <label>
        {{ 'form.password' | translate }}
        <input
            type="password"
            name="password"
            required
            minlength="8"
            (change)="checkPasswordsMatch()"
            [placeholder]="'form.password' | translate"
            [(ngModel)]="form.password"
            validationErrors
        />
    </label>
    <label>
        {{ 'form.passwordConfirm' | translate }}
        <input
            type="password"
            name="passwordConfirm"
            required
            (change)="checkPasswordsMatch()"
            [placeholder]="'form.passwordConfirm' | translate"
            [(ngModel)]="form.passwordConfirm"
            validationErrors
        />
    </label>
    <label>
        <input type="checkbox" name="termsAccepted" required [(ngModel)]="form.termsAccepted" />
        <span [innerHTML]="'form.termsAccepted' | translate : { termsUrl }"></span>
    </label>
    <button type="button" class="btn color-primary btn-login" (click)="goToLogin.emit()">
        <span>{{ 'buttons.backToLogin' | translate }}</span>
    </button>
</form>
