<div *ngIf="visible">
    <div class="title-start">
        <strong>{{ 'steps.done.start' | translate }}</strong>
        <p>{{ 'steps.done.start_subtitle' | translate }}</p>
    </div>
    <div class="title-download">
        <strong>{{ 'steps.done.download' | translate }}</strong>
        <p>{{ 'steps.done.download_subtitle' | translate }}</p>
    </div>
    <div class="store_logos">
        <a [href]="url.appStore" target="_blank">
            <img src="../../../assets/img/appstore.png" [alt]="'general.appStore' | translate" />
        </a>
        <a [href]="url.playStore" target="_blank">
            <img src="../../../assets/img/googleplay.png" [alt]="'general.googlePlay' | translate" />
        </a>
    </div>
</div>
