import { InjectionToken } from '@angular/core';

export interface IEnvironment {
    env: 'DEV';
    supportedLocales: Array<string>;
    defaultLocale: string;
    fallbackLocale: string;
}

export const ENVIRONMENT = new InjectionToken<IEnvironment>('environment');
