import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppSettingsService as SharedAppSettingService } from 'shared';
import { REPOSITORY_BASE_URL_TOKEN, RepositoryBaseUrlConfig } from 'shared';

import { AppSettings } from '../models/app-settings.model';

@Injectable({ providedIn: 'root' })
export class AppSettingsService extends SharedAppSettingService<AppSettings> {
    constructor(
        http: HttpClient,
        @Inject(REPOSITORY_BASE_URL_TOKEN) baseUrlConfig: BehaviorSubject<RepositoryBaseUrlConfig>
    ) {
        super(http, baseUrlConfig);
    }
}
