import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';

import {
    ApiVersion,
    ICredentials,
    ICredentialsPOST,
    ITenant,
    UserRegistrationModel,
    UserRegistrationResponseModel,
} from '../models';
import { AuthResponse } from '../models/auth-response.model';
import { TenantTemplateOptionModel } from '../models/tenant-template-option.model';
import { AuthConfigRepositoryService } from './auth-config-repository.service';

@Injectable({
    providedIn: 'root',
})
export class AuthRepositoryService {
    public constructor(private readonly http: HttpClient, private readonly authConfig: AuthConfigRepositoryService) {}

    public login(username: string, password: string): Observable<AuthResponse> {
        return this.getApiUrl(`/auth/login`, 'v2').pipe(
            switchMap((url) =>
                this.http.post<AuthResponse>(url, {
                    username,
                    password,
                })
            )
        );
    }

    public refresh(accessToken: string, refreshToken: string): Observable<AuthResponse> {
        return this.getApiUrl(`/auth/refreshtoken`, 'v2').pipe(
            switchMap((url) =>
                this.http.post<AuthResponse>(url, {
                    accessToken,
                    refreshToken,
                })
            )
        );
    }

    public putCredentials(credentials: ICredentialsPOST): Observable<void> {
        return this.getApiUrl('credentials', 'v1').pipe(mergeMap((url) => this.http.put<void>(url, credentials)));
    }

    public getCredentials(userId: string): Observable<ICredentials> {
        return this.getApiUrl(`credentials/${userId}`, 'v1').pipe(mergeMap((url) => this.http.get<ICredentials>(url)));
    }

    public registerUser(user: UserRegistrationModel): Observable<UserRegistrationResponseModel> {
        return this.getApiUrl('/auth/register', 'v1').pipe(
            mergeMap((url) => this.http.post<UserRegistrationResponseModel>(url, user))
        );
    }

    public updateTenant(name: string, tenantTemplateId: string, tenantId: string): Observable<ITenant> {
        return this.getApiUrl('/tenants', 'v1').pipe(
            mergeMap((url) => this.http.put<ITenant>(url, { name, tenantTemplateId, tenantId }))
        );
    }

    public getTenantTemplates(language = 'en'): Observable<Array<TenantTemplateOptionModel>> {
        return this.getApiUrl('/templates', 'v1').pipe(
            mergeMap((url) =>
                this.http.get<Array<TenantTemplateOptionModel>>(url, {
                    params: new HttpParams().append('language', language),
                })
            )
        );
    }

    private getApiUrl(resource: string, apiVersion: ApiVersion) {
        return this.authConfig.config.pipe(
            take(1),
            map((config) => `${config.baseUrl}/${apiVersion}${resource.startsWith('/') ? resource : '/' + resource}`)
        );
    }
}
