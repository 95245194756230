import { JSONSchema } from '@ngx-pwa/local-storage';
import { omit } from 'lodash';

export interface AuthUser {
    id: string;
    username: string;
    fullName: string;
    timestamp: Date;
}

export type StoredAuthUser = Omit<AuthUser, 'timestamp'> & {
    timestamp: number;
};

export const AuthUserSchema: JSONSchema = {
    type: 'object',
    required: ['id', 'username', 'fullName', 'timestamp'],
    properties: {
        id: { type: 'string' },
        username: { type: 'string' },
        fullName: { type: 'string' },
        timestamp: { type: 'number' },
    },
};

export const getUserForStoredValue = (user: StoredAuthUser): AuthUser => {
    return {
        ...omit(user, ['timestamp']),
        timestamp: new Date(user.timestamp * 1000),
    };
};

export const getStoredValueForUser = (user: AuthUser): StoredAuthUser => {
    return {
        ...omit(user, ['timestamp']),
        timestamp: Math.floor(user.timestamp.getTime() / 1000),
    };
};
