import { Injectable } from '@angular/core';
import { AuthRepositoryService, ITenant, TenantTemplateOptionModel } from 'baseflow-auth';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMapConfig, IMapConfigPUT, TenantApiService } from 'shared';

@Injectable({ providedIn: 'root' })
export class TenantService {
    private tenant?: ITenant;

    private constructor(private authRepository: AuthRepositoryService, private tenantApi: TenantApiService) {}

    public getTenantTemplates(language = 'en'): Observable<Array<TenantTemplateOptionModel>> {
        return this.authRepository.getTenantTemplates(language);
    }

    public updateTenant(name: string, tenantTemplateId: string): Observable<ITenant> {
        return this.authRepository.updateTenant(name, tenantTemplateId, this.tenant?.id);
    }

    public createTenant(name: string): Observable<ITenant> {
        return this.tenantApi.createTenant(name);
    }

    public setActiveTenant(tenant: ITenant): void {
        this.tenant = tenant;
    }

    public getActiveTenant(): ITenant {
        return this.tenant;
    }

    public updateConfig(config: Omit<IMapConfig, 'id' | 'ordinals'>): Observable<boolean> {
        if (!this.tenant) return throwError(() => 'Cannot update tenant config without an active tenant.');
        const { mapBox, ...rest } = config;
        const data: IMapConfigPUT = { ...rest, ...mapBox };
        return this.tenantApi.updateConfig(data, this.tenant.id).pipe(map(Boolean));
    }
}
