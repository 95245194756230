import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AccessTokenInterceptor } from './services/interceptors/access-token-interceptor';

@NgModule({
    declarations: [],
    imports: [HttpClientModule],
    exports: [],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AccessTokenInterceptor,
            multi: true,
        },
    ],
})
export class BaseflowAuthModule {}
