import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'baseflow-auth';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { fade } from 'shared';

import { AppSettingsService, TenantService } from '../../../services';
import { WizardStepBaseComponent } from '../base/wizard-step-base.component';


@Component({
    selector: 'app-wizard-step-login',
    templateUrl: './wizard-step-login.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', './wizard-step-login.component.scss'],
    animations: [fade()],
})
export class WizardStepLoginComponent extends WizardStepBaseComponent {
    protected loginError: string | null = null;
    public termsUrl: string;

    @Output() public goToSignup: EventEmitter<void> = new EventEmitter();

    public constructor(
        private readonly appSettings: AppSettingsService,
        private readonly translate: TranslateService,
        private readonly auth: AuthService,
        private readonly tenantService: TenantService
    ) {
        super();

        appSettings.get(tap((settings) => (this.termsUrl = settings.url.terms))).subscribe();
    }

    @HostListener('keyup.enter')
    public onSubmit(): void {
        this.stepSubmit.emit();
    }

    protected submitWizardStep(): Observable<boolean> {
        return (this.auth.isLoggedInSync() ? from(this.auth.logout()) : of(null)).pipe(
            take(1),
            switchMap(() => from(this.auth.login(this.form.emailAddress, this.form.password))),
            catchError((error) => this.handleLoginError(error)),
            map((result) => {
                if (result === false) return false;
                this.loginError = null;
                return true;
            })
        );
    }

    private handleLoginError(err: { code?: string; details: unknown }): Observable<false> {
        if (err?.code === 'auth/invalid-credentials') {
            this.loginError = 'errors.invalidCredentials';
        } else {
            console.error(err);
            this.loginError = 'errors.loginUnknownError';
        }
        return of(false);
    }
}
