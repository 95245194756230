import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parse } from 'url';

import { AuthConfigRepositoryService } from '../../repositories/auth-config-repository.service';

@Injectable({
    providedIn: 'root',
})
export class DomainListService {
    _whitelistedDomains: Array<string | RegExp> = [];
    _blacklistedRoutes: Array<string | RegExp> = [];

    public constructor(authConfig: AuthConfigRepositoryService) {
        authConfig.config.subscribe((config) => {
            if (config) {
                this._whitelistedDomains = config.whitelistedDomains || [];
                this._blacklistedRoutes = config.blacklistedRoutes || [];
            }
        });
    }

    isWhitelistedDomain = (request: HttpRequest<any>): boolean => {
        const requestUrl: any = parse(request.url, false, true);
        if (requestUrl.host === null) return false;
        return (
            this._whitelistedDomains.findIndex((domain) =>
                typeof domain === 'string'
                    ? domain === requestUrl.host
                    : domain instanceof RegExp
                    ? domain.test(requestUrl.host)
                    : false
            ) > -1
        );
    };

    isBlacklistedRoute = (request: HttpRequest<any>): boolean => {
        const url = request.url;

        return (
            this._blacklistedRoutes.findIndex((route) =>
                typeof route === 'string' ? route === url : route instanceof RegExp ? route.test(url) : false
            ) > -1
        );
    };
}
