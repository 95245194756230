import { InjectionToken } from '@angular/core';
import { AuthConfigRepositoryService, AuthService, BFAuthConfig } from 'baseflow-auth';
import { Observable, from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { AppSettingsService } from '../services';

export function AuthInitializerFactory<TAppSettings>(
    appSettingsService: AppSettingsService<TAppSettings>,
    authConfigRepository: AuthConfigRepositoryService,
    mapConfig: (appSettings: TAppSettings) => BFAuthConfig,
    authService: AuthService
) {
    // Initialize auth service
    return (): Observable<void> =>
        appSettingsService.appSettings.pipe(
            take(1),
            switchMap((appSettings) => from(authConfigRepository.initialize(mapConfig(appSettings)))),
            switchMap(() => authService.init())
        );
}

export const MAP_AUTH_CONFIG = new InjectionToken<<TAppSettings>(appSettings: TAppSettings) => BFAuthConfig>(
    'Map auth config using appsettings'
);
