import { BFAuthConfig } from 'baseflow-auth';

import { AppSettings } from '../models/app-settings.model';

export function MapAuthConfigFactory(appSettings: AppSettings): BFAuthConfig {
    return {
        baseUrl: appSettings.url.api.auth,
        usersApiBaseUrl: appSettings.url.api.users,
        whitelistedDomains: appSettings.url.jwtDomainWhitelist,
        blacklistedRoutes: [],
    };
}
