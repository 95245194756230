import { TranslateLoader } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';

// Using lazy loader instead of http loader to prevent accidental caching
// https://github.com/ngx-translate/http-loader/issues/25#issuecomment-514056865
export class LazyTranslateLoader implements TranslateLoader {
    public getTranslation(lang: string): Observable<unknown> {
        return from(import(`../../i18n/${lang}.json`));
    }
}
