<form #stepForm="ngForm" class="form-page" *ngIf="visible">
    <label>
        {{ 'form.emailAddress' | translate }}
        <input
            type="text"
            name="emailAddress"
            required
            [placeholder]="'form.emailAddress' | translate"
            [(ngModel)]="form.emailAddress"
            validationErrors
        />
    </label>
    <label>
        {{ 'form.password' | translate }}
        <input
            type="password"
            name="password"
            required
            [placeholder]="'form.password' | translate"
            [(ngModel)]="form.password"
            validationErrors
        />
    </label>
    <div class="login-error" @fade *ngIf="loginError">{{ loginError | translate }}</div>
    <button type="button" class="btn color-primary btn-signup" (click)="goToSignup.emit()">
        <span>{{ 'buttons.goToSignUp' | translate }}</span>
    </button>
</form>
