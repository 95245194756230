<form #stepForm="ngForm" *ngIf="visible" class="form-page">
    <div>
        <label class="warning-input">
            <input [(ngModel)]="enableMap" name="enableMap" type="checkbox" />
            <span>{{ 'steps.location.toggleMap' | translate }}</span>
        </label>
        <div *ngIf="!enableMap" @vshrink class="warning box">
            <i class="material-icons">warning</i>
            <span>{{ 'steps.location.mapWarning' | translate }}</span>
        </div>
    </div>
    <label id="mapSearch">
        {{ 'form.location' | translate }}
        <input
            (change)="geocodeLocation()"
            [(ngModel)]="form.location"
            [disabled]="!enableMap"
            [placeholder]="'form.locationSearch' | translate"
            class="location-search"
            name="location"
            type="text"
        />
    </label>
    <app-wizard-map [disabled]="!enableMap"></app-wizard-map>
    <div class="map-help">{{ 'steps.location.mapHelp' | translate }}</div>
</form>
