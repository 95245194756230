import { JSONSchema } from '@ngx-pwa/local-storage';
import jwt_decode from 'jwt-decode';

import { AuthResponse } from './auth-response.model';
import {
    AuthUser,
    AuthUserSchema,
    StoredAuthUser,
    getStoredValueForUser,
    getUserForStoredValue,
} from './auth-user.model';

export interface TokenSet {
    accessToken: string;
    accessTokenExpiry: Date;
    refreshToken: string;
    user: AuthUser;
}

export interface StoredTokenSet {
    accessToken: string;
    accessTokenExpiry: number;
    refreshToken: string;
    user: StoredAuthUser;
}

export const StoredTokenSetSchema: JSONSchema = {
    type: 'object',
    required: ['accessToken', 'refreshToken', 'accessTokenExpiry', 'user'],
    properties: {
        accessToken: { type: 'string' },
        refreshToken: { type: 'string' },
        accessTokenExpiry: { type: 'number' },
        user: AuthUserSchema,
    },
};

export const getTokenSetForStoredValue = (value: StoredTokenSet): TokenSet => {
    return {
        accessToken: value.accessToken,
        accessTokenExpiry: new Date(value.accessTokenExpiry * 1000),
        refreshToken: value.refreshToken,
        user: getUserForStoredValue(value.user),
    };
};

export const getStoredValueForTokenSet = (set: TokenSet): StoredTokenSet => {
    return {
        accessToken: set.accessToken,
        accessTokenExpiry: Math.floor(set.accessTokenExpiry.getTime() / 1000),
        refreshToken: set.refreshToken,
        user: getStoredValueForUser(set.user),
    };
};

export const getTokenSetForResponse = (resp: AuthResponse): TokenSet => {
    const { exp: accessExp } = jwt_decode(resp.accessToken) as any;
    return {
        accessToken: resp.accessToken,
        accessTokenExpiry: new Date(accessExp * 1000),
        refreshToken: resp.refreshToken,
        user: getUserForStoredValue(resp.user),
    };
};
