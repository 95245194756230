import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { DomainListService } from '../domain-list/domain-list.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
    public constructor(private authService: AuthService, private domainListService: DomainListService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const tokenSet = this.authService.getTokenSet();
        if (
            tokenSet &&
            this.domainListService.isWhitelistedDomain(req) &&
            !this.domainListService.isBlacklistedRoute(req)
        ) {
            req = req.clone({
                headers: req.headers.append('Authorization', 'Bearer ' + tokenSet.accessToken),
            });
        }
        return next.handle(req);
    }
}
