import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';


@Component({
    selector: 'ogs-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: true,
    imports: [NgFor],
})
export class SpinnerComponent {
    @Input() public size: string = null;
    @Input() public color: string = null;
}
