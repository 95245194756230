import { NgModule } from '@angular/core';

import { REPOSITORY_BASE_URL_TOKEN, RepositoryBaseUrlConfigFactory } from './services';


@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [
        {
            provide: REPOSITORY_BASE_URL_TOKEN,
            useFactory: RepositoryBaseUrlConfigFactory,
        },
    ],
})
export class SharedModule {}
